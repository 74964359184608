import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { Close } from "../img/Close";
import { LoadingCircle } from "../img/LoadingCircle";
import { RightArrow } from "../img/RightArrow";
import { IAgentCreate } from "../interfaces/Agent";
import { ICityResult } from "../interfaces/ICityResult";
import { default_location, default_voice } from "../utils/consts";
import {
  showFriendlyErrorFromData,
  titleCase,
  useMediaMobile,
  useNavigateToTop,
  useReferer,
  useWindowSize,
} from "../utils/utils";
import { ErrorAlert } from "./alert/ErrorAlert";
import { NucleusButton } from "./NucleusButton";
import { RemoveMemberDialog } from "./dialog/RemoveMemberDialog";
import { ProAlertDialog } from "./dialog/ProAlertDialog";

function formatCity(city: ICityResult): string {
  return city.city + ", " + city.region;
}
function unformatCity(cities: ICityResult[], location: string) {
  const parts = location.split(",", -1);
  const city = parts[0].trim();
  const region = parts[1].trim();
  return cities.find((c) => c.city === city && c.region === region);
}

interface IFormInput {
  email: string;
  cityId: ICityResult;
  businessName: string;
  voice: string;
  agents?: IAgentCreate[];
}
const locationSchema = {
  city: yup.string().required(),
  region: yup.string().required().length(2),
  country: yup.string().required().length(2),
};

const agentSchema = {
  id: yup.string(),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  phone: yup
    .string()
    .required("Phone number is required.")
    .test((phone, ctx) => {
      if (!isValidPhoneNumber(phone, "CA"))
        return ctx.createError({
          message: `${phone} is not a valid phone number`,
        });
      return true;
    }),
};

const schema = yup
  .object({
    cityId: yup.object(locationSchema),
    email: yup
      .string()
      .required("Please enter a valid email address.")
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, {
        message: "Please enter a valid email address.",
        excludeEmptyString: true,
      }),
    businessName: yup.string().required("Business name field is required."),
    voice: yup.string().required("Voice field is required."),
    agents: yup
      .array()
      .min(1, "Oops. Please add at least one user for your system.")
      .of(yup.object().shape(agentSchema)),
  })
  .required();

export default function LandingViewForm() {
  const referer = useReferer();
  const theme = useTheme();
  const isMobile = useMediaMobile();
  const {
    data: {authToken},
    actions: { isLoggedIn, createGuest },
  } = useContext(AuthContext);
  const {
    data: { cities: defaultCities, voices },
    actions: { setWarning },
  } = useContext(ApiContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState("");
  const [inputValue, setInputValue] = useState<string>(
    formatCity(default_location())
  );
  const [cities, setCities] = useState<ICityResult[]>(defaultCities);

  const setErrorAndScroll = (err: any) => {
    setError(err);
    setTimeout(() => {
      document.getElementById("errorAlert")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 250);
  };

  const { control, handleSubmit, setValue, getValues, formState, reset } =
    useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        email: "",
        cityId: default_location(),
        businessName: "",
        voice: default_voice(),
        agents: [{ id: uuidv4(), first_name: "", last_name: "", phone: "" }],
      },
    });

  useEffect(() => {
    setCities(defaultCities);
  }, [defaultCities]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const cityId = getValues("cityId");
      if (!cityId) {
        const i = cities.findIndex(
          (item) => item.city === default_location().city
        );
        setValue("cityId", i >= 0 ? default_location() : cities[0]);
        setInputValue(formatCity(getValues("cityId")));
      }
    }
  }, [cities, getValues, setValue]);

  useEffect(() => {
    if (voices && voices.length) {
      const voice = getValues("voice");
      if (!voice) setValue("voice", voices[0].name.toLowerCase());
    }
  }, [voices, getValues, setValue]);

  const { append, fields, remove } = useFieldArray({
    control,
    name: "agents",
  });

  const handleAddAgent = () => {
    if (fields.length < 10) {
      append({ id: uuidv4(), first_name: "", last_name: "", phone: "" });
    }
  };

  const [dialogIndex, setDialogIndex] = useState(-1);
  const handleRemoveAgent = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    }
  };
  const navHome = (token: any) => {
    const baseUrl =  process.env.REACT_APP_APP_URL;
    if(baseUrl)
      window.parent.location.href = baseUrl +"/check?token=" + token
  };

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    const { email, cityId, businessName, voice, agents } = data;
    if (!agents || agents?.length <= 0) return;
    if (loading) return;

    setError("");
    setSuccess("");
    setLoading(true);
    createGuest(email, cityId, businessName, voice, agents, referer)
      .then((data) => {
        setError(null);
        const msg = showFriendlyErrorFromData(data, "");
        if (msg) setWarning(msg);
        navHome(data?.data?.access_token);
      })
      .catch((error) => {
        setErrorAndScroll(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [canFocus, setCanFocus] = useState(true);
  const onError = () => {
    setCanFocus(true);
  };
  useEffect(() => { 
    if (formState.errors && canFocus) {
      const keyify = (obj: any, prefix = ""): string[] =>
        Object.keys(obj).reduce((res: string[], el: string) => {
          if (typeof obj[el] === "object" && obj[el] !== null) {
            return [...res, ...keyify(obj[el], prefix + el + ".")];
          }
          return [...res, prefix + el];
        }, []);

      // Sort inputs based on their position on the page. (the order will be based on validaton order otherwise)
      const errors = formState.errors;
      const keys = keyify(errors);
      const filterKeys = keys
        .filter((key) => key.endsWith(".message"))
        .map((key) => key.slice(0, -1 * ".message".length));

      const elements = filterKeys
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
      );

      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false); // so the form doesn't suddenly jump to the next input that has error.
      }
    }
  }, [formState, canFocus]);

  const onClickRemove = (index: number) => {
    const values = getValues();
    const agents = values.agents || [];
    const agent = agents[index];
    if (agent && !agent.first_name && !agent.last_name && !agent.phone)
      handleRemoveAgent(dialogIndex);
    else setDialogIndex(index);
  };

  return (
    <Paper>

      <form className="p-5 w-[100%]" onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack
          direction="column"
          spacing={1.5}
          justifyContent="center"
          alignItems="stretch"
        >
          <ErrorAlert id="errorAlert" error={error} />
          {success && <Alert severity="success">{success} </Alert>}

          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ paddingBottom: 2 }}>
                Setup your AI Attendant
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <TextField
                      id="email"
                      {...field}
                      error={formState.errors.email !== undefined}
                      helperText={
                        formState.errors.email
                          ? formState.errors.email?.message?.toString()
                          : "　"
                      }
                      className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                      type="email"
                      placeholder="Email"
                      inputProps={{
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={5.9}>
              <Controller
                name="cityId"
                control={control}
                render={({ field }) => {
                  const e = formState.errors.cityId?.message;
                  return (
                    <FormControl sx={{ width: "100%" }} error={e !== undefined}>
                      <FormLabel htmlFor="cityIdOption">Phone Region</FormLabel>
                      <Autocomplete
                        id="cityIdOption"
                        autoHighlight
                        value={formatCity(field.value)}
                        onChange={(event: any, newValue: string | null) => {
                          field.onChange(
                            newValue
                              ? unformatCity(cities, newValue)
                              : default_location()
                          );
                        }}
                        blurOnSelect
                        disableClearable
                        autoComplete
                        loading={loading}
                        options={cities
                          .filter((value) =>
                            inputValue.length <= 0 ? value.is_preferred : true
                          )
                          .map((item) => formatCity(item))}
                        inputValue={inputValue}
                        onInputChange={(
                          e: React.SyntheticEvent,
                          newInputValue
                        ) => {
                          setInputValue(newInputValue);
                        }}
                        onFocus={() => {
                          setCities(defaultCities);
                          setInputValue("");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Start typing your city"
                            InputProps={{
                              ...params.InputProps,
                              sx: { boxShadow: "none", padding: "1px 3px" },
                            }}
                          />
                        )}
                        sx={{ width: "100%" }}
                      />
                      <FormHelperText>{e ? e.toString() : "　"}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={0} md={0.2} />
            <Grid item xs={12} md={5.9}>
              <Controller
                name="voice"
                control={control}
                render={({ field }) => {
                  const e = formState.errors.voice?.message;
                  return (
                    <FormControl sx={{ width: "100%" }} error={e !== undefined}>
                      <FormLabel htmlFor="voiceId">Attendant Voice</FormLabel>
                      <Select
                        id="voiceId"
                        {...field}
                        defaultValue={
                          default_voice()
                        }
                      >
                        {voices
                          ?.filter((v) => v.is_premium === false)
                          .map((v) => {
                            return (
                              <MenuItem
                                key={v.name.toLowerCase()}
                                value={v.name.toLowerCase()}
                              >
                                {titleCase(v.name)}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>{e ? e.toString() : "　"}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="businessName"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel htmlFor="businessName">
                      Your Business Name
                    </FormLabel>
                    <TextField
                      id="businessName"
                      {...field}
                      error={formState.errors.businessName !== undefined}
                      helperText={
                        formState.errors.businessName
                          ? formState.errors.businessName?.message?.toString()
                          : "　"
                      }
                      placeholder="Business Name"
                      className="rounded-md w-full !ring-transparent !outline-none focus:outline-none bg-white/10 focus:border-white/80 transition-all focus:bg-white/5"
                      type="text"
                      inputProps={{
                        style: {
                          padding: "10px 12px",
                        },
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <>
                {fields.map((agent, index) => (
                  <Grid
                    key={agent.id}
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        name={`agents.${index}.first_name`}
                        render={({ field }) => {
                          const e =
                            formState.errors?.agents?.at !== undefined &&
                            formState.errors?.agents.at(index) !== undefined &&
                            formState.errors?.agents?.at(index)?.first_name
                              ?.message;
                          console.log(e);
                          return (
                            <>
                              <FormLabel>First name</FormLabel>
                              <TextField
                                {...field}
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                sx={{ width: "100%" }}
                                type="text"
                                placeholder="First Name"
                                inputProps={{
                                  maxLength: 50,
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        control={control}
                        name={`agents.${index}.last_name`}
                        render={({ field }) => {
                          const e =
                            formState.errors?.agents?.at !== undefined &&
                            formState.errors?.agents.at(index) !== undefined &&
                            formState.errors?.agents?.at(index)?.last_name
                              ?.message;
                          return (
                            <>
                              <FormLabel>Last name</FormLabel>
                              <TextField
                                {...field}
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                sx={{ width: "100%" }}
                                type="text"
                                placeholder="Last Name"
                                inputProps={{
                                  maxLength: 50,
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={fields.length > 1 ? 3 : 4}>
                      <Controller
                        control={control}
                        name={`agents.${index}.phone`}
                        render={({ field }) => {
                          const e =
                            formState.errors?.agents?.at !== undefined &&
                            formState.errors?.agents.at(index) !== undefined &&
                            formState.errors?.agents?.at(index)?.phone?.message;
                          return (
                            <>
                              <FormLabel>Phone</FormLabel>
                              <TextField
                                {...field}
                                error={e !== undefined && e !== false}
                                helperText={e ? e.toString() : "　"}
                                sx={{ width: "100%" }}
                                type="text"
                                placeholder="Phone"
                                inputProps={{
                                  maxLength: 50,
                                  style: {
                                    padding: "10px 12px",
                                  },
                                }}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    {fields.length > 1 && (
                      <Grid item xs={12} md={1}>
                        <RemoveMemberDialog
                          open={dialogIndex >= 0}
                          onClose={() => setDialogIndex(-1)}
                          onCancel={() => setDialogIndex(-1)}
                          onOk={() => {
                            setDialogIndex(-1);
                            handleRemoveAgent(dialogIndex);
                          }}
                        />

                        {!isMobile ? (
                          <IconButton
                            disabled={fields.length <= 1}
                            onClick={() => onClickRemove(index)}
                          >
                            <Close />
                          </IconButton>
                        ) : (
                          <Button
                            sx={{
                              width: "100%",
                              color: theme.palette.error.dark,
                              marginBottom: "32px",
                            }}
                            variant="outlined"
                            onClick={() => onClickRemove(index)}
                          >
                            Remove team member
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Grid>
                ))}

                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                  rowSpacing={2}
                >
                  <Grid item xs={12} md={4}></Grid>
                  {!isMobile && <Grid item xs={0} md={4}></Grid>}
                  <Grid item xs={12} md={4} sx={{ textAlign: "right" }}>
                    <NucleusButton
                      variant="contained"
                      sx={{ width: isMobile ? "100%" : "220px" }}
                      type={isLoggedIn() ? "button" : "submit"}
                      onClick={() => {
                        if (isLoggedIn()) navHome(authToken?.access_token);
                      }}
                    >
                      {isLoggedIn() ? "Go to Dashboard" : "Create my account"}
                      {loading ? (
                        <LoadingCircle />
                      ) : (
                        <div className="flex items-center justify-center w-3 ml-1">
                          <RightArrow />
                        </div>
                      )}
                    </NucleusButton>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </Paper>
  );
}
